import React, { useMemo } from 'react';
import { PageType } from '@marty-js/api-sdk/types';
import { ItemPageContent } from './item-page-content';
import { SectionPageContent } from './section-page-content';
import { PageContentProps } from './template/types';
import { useDatalayer } from '../utils/AnalyticsContext';

function PageContent({ pageInfo, set0verrideAdUnit, data }: PageContentProps) {
  const authors = useMemo(() => {
    const authorTitles: string[] = pageInfo?.item?.linkedWith?.author?.map((authorLink) => authorLink.title);

    return authorTitles;
  }, [pageInfo]);

  useDatalayer({
    contentType: data?.itemData?.item?.layout ?? pageInfo.layout,
    title: pageInfo.title,
    publishedAt: pageInfo?.item?.publishedAt,
    commentsCount: pageInfo?.item?.comments,
    tags: pageInfo?.item?.tagSlugs,
    author: authors ?? [],
  });

  switch (pageInfo.pageType) {
    case PageType.item:
      return <ItemPageContent pageInfo={pageInfo} data={data} set0verrideAdUnit={set0verrideAdUnit} />;
    case PageType.section:
      return <SectionPageContent pageInfo={pageInfo} data={data} set0verrideAdUnit={set0verrideAdUnit} />;
    default:
      return null;
  }
}

export default PageContent;
